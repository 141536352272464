
// api.js
import { ORCHESTATION_URL } from "../../configuration/vortexConfig";
import toast from 'react-hot-toast';

const getToken = () => {
    return sessionStorage.getItem('__authVortexOrquestator'); 
};

// Función interceptor para realizar peticiones con token
const fetchWithToken = async (url: string, options: any = {}, addHeader: boolean = true) => {

    const token = getToken();

    // Add token to request
    const headers = {
        ...options.headers,
        ...(token ? { 'Authorization': `Bearer ${token}` } : {}),
        "X-Orchestator-Header": "Vortex-Chat" // Custom header
    };

    if ( addHeader) headers['Content-Type'] = 'application/json';

    try {
        const response = await fetch(`${ORCHESTATION_URL}${url}`, {
            ...options,
            headers,
        });

        // Manejo de errores (opcional)
        if (!response.ok) {
            const error = await response.json();
            throw new Error(error.message);
        }

        return response;
    } catch (error) {
        toast.error(`Error en la peticion ${error}`);
        throw error;
    }
};

// Exportar la función para usarla en otros componentes
export { fetchWithToken };