// chat.tsx
import { useState, useContext, createRef, useEffect } from 'react';

import { ChatUserContext } from '../../core/chatContext';
import { ChatController } from './chat.controller';
import { getAPIResponse } from '../../core/http';

import { WelcomeComponent } from '../common/welcome/main';
import { DialogComponent } from '../common/dialog';
import { FileContextComponent  } from './fileContext/fileContext';
import { FooterComponent } from './footer/footer';
import toast from 'react-hot-toast';
import styles from './chat.module.scss';

import Send from '@mui/icons-material/Send';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import StopCircleOutlinedIcon from '@mui/icons-material/StopCircleOutlined';

const ChatComponent: React.FC<{ idUser: any, userPhoto: any }> = ({ idUser, userPhoto }) => {

  const Chat_Controller = new ChatController();

  const currChatContext: any = useContext(ChatUserContext);

  const textAreaRef = createRef<HTMLTextAreaElement>();

  const [fileName, setFileName] = useState('');
  const [streamingActive, setStreamingActive ] = useState(false);
  const [abortController, setAbortController] = useState<AbortController | null>(null);
  
  useEffect(() => {
    // Focus in textarea when render page
    textAreaRef.current?.focus();
  });

  const handleStopStreaming = () => {
    if (abortController) {
      abortController.abort();
      setStreamingActive(false);
    }
  };

  const PromptComponent = () => {
    const [formData, setFormData] = useState({ question: "" });

    const handleTextareaKeyPress = (e: any) => {
      if (e.key === 'Enter' && !e.shiftKey) {
        e.preventDefault();
        handleSubmit(e);
      }
    };

    const handleSubmit = (e: any) => {
      e.preventDefault();

      if (formData.question) {
        // Add new question to array
        currChatContext['setCurrChat']((prevContext: RequestOpenAI) => {
          prevContext.messages = [...prevContext.messages || [], {
            "role": "user",
            "content": formData.question
          }]
          return prevContext
        });

        // Update user question
        currChatContext.setUserAsks((prevArray: any) => [...prevArray, formData.question]);

        // Add loading message
        currChatContext.setIaAnswers((prevArray: DialogComponents[]) => [...prevArray, {id: '0', content: 'Generando respuesta...'}]);
        
        const newAbortController = new AbortController();
        setAbortController(newAbortController);
        setStreamingActive(true);
        let apiResponse = '';
        getAPIResponse(currChatContext.currChat, idUser, (chunk) => {
          if (!newAbortController.signal.aborted) {
            chunk.forEach((part: any) => {
              apiResponse += part.delta.content;
              currChatContext.setIaAnswers((prevArray: DialogComponents[]) => [...prevArray.slice(0, prevArray.length - 1), { id: '0', content: apiResponse }]);
            });            
          } 
        }, newAbortController)
        .then( () => {
          Chat_Controller.saveResponse(currChatContext, idUser, formData.question, apiResponse);
        })
        .catch( (error) => {
          if ( error === 'Request aborted by user' ) {
            currChatContext.setIaAnswers((prevArray: DialogComponents[]) => [...prevArray.slice(0, prevArray.length - 1), { id: '0', content: 'Petición cancelada por el usuario.' }]);
          }
          console.error('Error', error);
          currChatContext.setIaAnswers((prevArray: DialogComponents[]) => [...prevArray.slice(0, prevArray.length - 1), { id: '0', content: 'Oops, parece que hay problema de conexión con el servidor central.' }]);
        })
        .finally(() => { 
          setStreamingActive(false);
        })
      }
    };

    const handleFileChange = (event: any) => {
      const _file = event.target.files[0]
      const _formData = new FormData();
      _formData.append('pdf_file', _file);

      if (Chat_Controller.checkFileIsPdf(_file) && Chat_Controller.checkFileSize(_file.size)) {
        currChatContext.currChat.data_sources[0].parameters.ephemeralId = crypto.randomUUID();
        setFileName('Cargando el fichero. Un momento por favor ...');
        Chat_Controller.uploadFile(_formData, currChatContext.currChat.data_sources[0].parameters.ephemeralId)
          .then(() => {
            // Create new conversation context
            // Remove previous conversation
            currChatContext.setUserAsks([]);
            currChatContext.setIaAnswers([]);
            // Remove conversation
            currChatContext.setCurrChat((prevCurrChat: any) => {
                // Remove chat history
                prevCurrChat.messages = [];
                // Indicate start new document
                prevCurrChat['data_sources'][0]['parameters']['new_document'] = true;
                // Indicate new ephemaral document id
                prevCurrChat['data_sources'][0].parameters.ephemeralId = currChatContext.currChat.data_sources[0].parameters.ephemeralId;       
                return prevCurrChat;
            }); 
            setFileName(_file['name']);
          });
      } else {
        toast.error('El fichero supera el tamaño máximo permitido o no es del tipo correcto');
      }
    };

    return (
        <div className={styles.promptComponentContainer}>
            
            <div className={styles.promptComponentComponent}>
                <form className={styles.promptComponentComponentInput} onSubmit={handleSubmit}>
                    <div className={styles.promptComponentComponentUploadfile}>
                        <label htmlFor="file-upload" style={{ cursor: 'pointer' }}>
                            <div className={styles.promptComponentComponentUploadButton}>
                                <AttachFileIcon className={styles.promptComponentComponentIcon}></AttachFileIcon>
                            </div>
                        </label>
                        <input
                            id="file-upload"
                            type="file"
                            style={{ display: 'none' }}
                            onChange={handleFileChange}
                        />
                    </div>
                    <div className={styles.promptComponentComponentInputTextareaContainer}>
                        <textarea 
                            ref={textAreaRef}
                            className={styles.promptComponentComponentInputTextarea} 
                            onChange={(e) => {
                              setFormData({...formData, question: e.target.value})
                              e.target.style.height = 'auto'
                              e.target.style.height = `${e.target.scrollHeight}px`
                            }}
                            onKeyDown={handleTextareaKeyPress} // Handles the "Enter" key
                            value={formData.question} 
                            id="body"
                            name="body" 
                            placeholder='En qué te puedo ayudar'
                            rows={1}
                            style={{overflowY: 'auto'}}></textarea>
                    </div>
                    {
                      !streamingActive && ( 
                        <button className={styles.promptComponentComponentSendButton} type='submit'>
                          <Send className={styles.promptComponentComponentIcon}></Send>
                        </button>
                      )
                    }

                </form>
            </div>
        </div>
    )
  }

  const showMainComponent = () => {
    if ( !currChatContext.currChat.hasOwnProperty('messages') || currChatContext.currChat.messages.length === 0) {
        return (
            <WelcomeComponent/>
        )
    } else {
        return (
            <DialogComponent 
              userId={idUser}
              userPhoto={userPhoto}
              userAsks={currChatContext.userAsks}
              iaAnswers={currChatContext.iaAnswers}
              enabledFeedBack={true}
            ></DialogComponent>
        )
    }
  }


  return(
      <div className={styles.chatComponent}>
        {showMainComponent()}
        <FileContextComponent fileName={fileName} setFileName={setFileName} currChatContext={currChatContext}></FileContextComponent>
        <div className={styles.promptContainer}>
          <PromptComponent />
          {streamingActive && ( 
            <button type="button" onClick={handleStopStreaming} className={styles.promptComponentComponentCloseButton}>
              <StopCircleOutlinedIcon className={styles.promptComponentComponentIcon}></StopCircleOutlinedIcon>
            </button>
          )}
        </div>
        <FooterComponent />
      </div>
  )
}

export { ChatComponent }
