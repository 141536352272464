/* header.tsx */
import React from 'react';
import { Dropdown  } from './context/context';

import styles from './main.module.scss';
import logoSeat from './../../../assets/img/AF_LOGO_SEAT_SA_negativo_RGB.svg';
import confidential from './../../../assets/img/Confidential.svg';

interface iHeaderProps {
    srcImage?: string;
    enabledContext?: boolean;
}

const _HeaderComponent: React.FC<{userChatIdx: UserProfile | null, headerProps: iHeaderProps}> = ({userChatIdx, headerProps}) => {

    const SelectContextComponent = () => {
        return headerProps.enabledContext
            ? (
                <div className={styles.headerTitle}>
                    <div className={styles.headerTitleContext}>
                        Contexto Conectado:
                    </div>
                    <div className={styles.headerTitleDescription}>
                        <Dropdown allowIndexes={userChatIdx?.allowIndexes || []}></Dropdown>
                    </div>
                </div>
            )
            : ( <></> )
    }
    
    return (
        <div className={styles.header}>
            <div className={styles.headerLogoBlocksSeat}>
                <img src={logoSeat} className={styles.headerLogoSeat} alt="logo" />
                <img src={confidential} className={styles.headerLogoConfidential} alt="logo" />
            </div>
            <div className={styles.headerLogoBlocksVortex}>
                <img src={headerProps.srcImage} className={styles.headerLogoVortex} alt="logo" />
            </div>
            <div className={styles.headerLogoBlocksContext}>
                <div className={styles.headerUserInfo}>
                    { userChatIdx?.userPhoto && <img src={userChatIdx?.userPhoto} className={styles.headerUserInfoPhoto} alt="Foto de perfil" />}
                    <span className={styles.headerUserInfoName}>
                        {userChatIdx?.name}
                    </span>
                </div>
                <SelectContextComponent></SelectContextComponent>
            </div>
        </div>
    )
}

// Check if any data is change before render again
const areEqual = (prevProps: { userChatIdx: UserProfile | null, headerProps: iHeaderProps }, nextProps: { userChatIdx: UserProfile | null, headerProps: iHeaderProps }) => {
    return (
        prevProps.userChatIdx?.userPhoto === nextProps.userChatIdx?.userPhoto &&
        prevProps.userChatIdx?.name === nextProps.userChatIdx?.name &&
        prevProps.headerProps.srcImage === nextProps.headerProps.srcImage &&
        prevProps.headerProps.enabledContext === nextProps.headerProps.enabledContext
    );
};

const HeaderComponent = React.memo(_HeaderComponent, areEqual);

export { HeaderComponent }
