// import { getAPIResponse } from "../../core/http";
import { CosmosConn } from '../../core/cosmos/api';
import { ChatMessage, Conversation } from "../../core/cosmos";
import { fetchWithToken } from "../../core/interceptor";

import { SIZE_CONVERSATION, API_DATA_SOURCES } from "../../configuration/vortexConfig";


export class ChatController {

    // private async generateAnswer(aiApiRequest: RequestOpenAI, accessToken: string, onChunkReceived: (chunk: any) => void): Promise<any> {
    //     return new Promise(async (resolve, reject) => {
    //         const response = await getAPIResponse(aiApiRequest, accessToken, onChunkReceived);
    //         let message: any[] = [];
    //         response.forEach((element: any) => {
    //             if (element.delta.content) {
    //                 if (element.delta.content === "Has sobrepasado el de tiempo de espera programado. Por favor, refresca tu navegador e intentalo de nuevo.")
    //                     reject("Has sobrepasado el de tiempo de espera programado. Por favor, refresca tu navegador e intentalo de nuevo.");
    //                 message.push(element);
    //             }
    //         });
    //         resolve(message);
    //     });
    // }

    private addMessageToConversation(
        idUser: string,
        isNewConversation: boolean,
        conversationId: string,
        prevConversation: {
            prev: Conversation[],
            question: string, 
            apiResponse: string
    }): {
        createConversation: boolean, 
        conversation: Conversation | null,
        history: Conversation[] | null
    } | null {

        function __getIndexConversation(): number{
          let _conversationIndex = 0;
          prevConversation.prev.forEach( (currConversation: Conversation, idxConversation: number) => {
            if ( currConversation.id === conversationId ) _conversationIndex = idxConversation;
          });
          return _conversationIndex
        }

        const conversationIndex = __getIndexConversation();

        const NumberMessages: number = prevConversation.prev?.length ? prevConversation.prev[conversationIndex].messages.length : 0;
        
        const addMessage = (question: string, response: string): ChatMessage[] => {
            return [
                {
                "id": crypto.randomUUID(),
                "role": "user",
                "content": question,
                "date": (new Date()).toISOString()
            },{
                "id": crypto.randomUUID(),
                "role": "assistant",
                "content": response,
                "date": (new Date()).toISOString()
            }]
        }

        const dateFirstMessage = prevConversation.prev.length ? new Date(prevConversation.prev[0].date).toDateString() : new Date();
        let newMessage: Conversation = {
            "id": crypto.randomUUID(),
            "user": idUser,
            "messages": [],
            "date": (new Date()).toISOString()
        }

        const createNewMessage = () => {
          // prevConversation.prev[conversationIndex].messages.push(...addMessage(prevConversation.question, prevConversation.apiResponse));

          // Create new conversation
          newMessage.messages = [...addMessage(prevConversation.question, prevConversation.apiResponse)];
          // Add new conversation at the beginning to history
          prevConversation.prev.unshift(newMessage);

          return {
              createConversation: true,
              conversation: newMessage,
              history: prevConversation.prev
          };

        }

        // Check spaces of messages in conversation
        switch( true ) {
            case NumberMessages === 0:
              return createNewMessage();

            case NumberMessages + 2 > SIZE_CONVERSATION * 2:
              return createNewMessage();

            case dateFirstMessage !== new Date().toDateString():
              return createNewMessage();

            case isNewConversation:
              return createNewMessage();

            case !conversationId:
              return createNewMessage();
              
            default:
              // Add new message to history
              prevConversation.prev[conversationIndex].messages.push(...addMessage(prevConversation.question, prevConversation.apiResponse));
              newMessage.messages = [...prevConversation.prev[conversationIndex].messages, ...addMessage(prevConversation.question, prevConversation.apiResponse)];
              // Recovery conversation id to add to it
              newMessage.id = conversationId;
              return {
                  createConversation: false,
                  conversation: newMessage,
                  history: prevConversation.prev
              };
        }
    }

    private updateCurrChatContext(_currChatContext: any): void {
      // Change new_document context to false. Not create new messages
      _currChatContext.setCurrChat((prevCurrChat: any) => {
        prevCurrChat['data_sources'][0]['parameters']['new_document'] = false;
        return prevCurrChat;
      });
    }

    saveResponse(currChatContext: any, idUser: string, question: string, apiResponse : string): void {
        const _currChatContext = currChatContext;
        const Cosmo_Conn = new CosmosConn();

        // Add api response to history context and save @ cosmodb
        currChatContext.setCurrHistory((curr: any) => {
          let { createConversation, conversation, history = []} = { ...this.addMessageToConversation(
            idUser,
            _currChatContext.currChat.data_sources[0].parameters.new_document,
            _currChatContext.currChat.data_sources[0].parameters.conversationId,
            {
              prev: curr,
              question: question,
              apiResponse: apiResponse
            }
          )};

          // Update IaAnswer with id, to trace responses
          currChatContext.setIaAnswers((prevArray: DialogComponents[]) => {
            // Add last answer to dialog
            return [...prevArray.slice(0, prevArray.length - 1), conversation?.messages.slice(-1)[0]]
          });

          // If it's new conversation update createConversation value and modify currChatContext
          if ( _currChatContext.currChat.data_sources[0].parameters.new_document) {
            createConversation = true;
            this.updateCurrChatContext(_currChatContext);
          }

          // Save conversation @ cosmodb
          if (conversation) (async () => {
            await Cosmo_Conn.addConversation(currChatContext.accessToken, conversation);
            _currChatContext.currChat.data_sources[0].parameters.conversationId = conversation.id;
          })();

          if (createConversation) {
            // Add api response to chat context
            currChatContext.setCurrChat((prevContext: RequestOpenAI) => {
              prevContext.messages = [...prevContext.messages || [], {
                "role": "assistant",
                "content": apiResponse
              }]
              return prevContext
            });
            // Add conversation to currHistory
            // return [conversation, ...curr]
            return history;  // Update setCurrHistory
          } else {
            // Update chat context
            // If it is last message of context create new context.
            currChatContext.setCurrChat((prevContext: RequestOpenAI) => {
              if (prevContext.messages?.length === API_DATA_SOURCES.parameters.top_n_documents) {
                prevContext.messages = [];  // Initialize messages
                return prevContext
              } else {
                prevContext.messages = [...prevContext.messages || [], {
                  "role": "assistant",
                  "content": apiResponse
                }]
                return prevContext
              }
            });

            // Add conversation to currHistory
            // return [conversation, ...curr.slice(1,)]
            return history;  // Update setCurrHistory
          }
        });
            
          



    }

    generatePrompt(message: string) {
        const newMessage = {
            role: 'user',
            content: message
        }
        return  [
            newMessage
        ]
        
    }

    checkFileIsPdf(file: File): boolean {
        return file.type === "application/pdf"
    }

    checkFileSize(fileSize: number){
      const MAX_SIZE_MB = 16; // Maximum file size in MB
      const MAX_SIZE_BYTES = MAX_SIZE_MB * 1024 * 1024; // 
      return MAX_SIZE_BYTES > fileSize ? true : false;
    }

    async uploadFile(formData: any, uuid: string): Promise<boolean>  {
      return new Promise( async(resolve: any) => {
          if ( formData ) {
              try {              
                const response = await fetchWithToken(`/v1/ephemeral/upload/${uuid}`,{
                  method: 'POST',
                  body: formData
                }, false);
                if ( response.status === 204 )  resolve(true);
              }
              catch (error) {
                console.error('Error:', error); 
              }
          };
          resolve(false);
      })
    }

    async RemoveFile(uuid: string | null): Promise<boolean>  {
        return new Promise( async(resolve: any) => {
            if ( uuid ) {
              try {
                const response = await fetchWithToken(`/v1/ephemeral/collection/${uuid}`,{
                  method: 'DELETE'
                });
                if ( response.status === 204 ) resolve(true);
              }
              catch (error) {
                console.error('Error:', error); 
              }
            };
            resolve(false);
        })
    } 
}

