
import { CosmosConn } from '../../../core/cosmos/api';

import styles from './feedBack.module.scss';

import ThumbDownOffAltIcon from '@mui/icons-material/ThumbDownOffAlt';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import toast from 'react-hot-toast';




const FeedbackComponent: React.FC<{userId: string, conversationId: string, idx: string}> = ({ userId, conversationId, idx}) => {

    const evaluateResponse = (action: string, conversationId: string, messageId: string) => {
        const Cosmos_Connection = new CosmosConn();
        Cosmos_Connection.evaluateConversation(userId, conversationId, messageId, action).then( (response) => {
            if ( response ) {
                // Show information of transaction
                toast.success("Mensaje correctamente evaluado", {
                    duration: 1000,
                    style: {
                        border: '1px black',
                        background: '#fff',
                        color: 'black',
                        padding: '20px'
                    }
                })
            } else {
                toast.error("Error al evaluar el mensaje", {
                    duration: 8000,
                    style: {
                        border: '1px black',
                        background: '#CD5C5C',
                        color: 'white',
                        padding: '20px'
                    }
                })
            }
        })   
    }

    return (
        <div className={styles.dialogFeedback}>
            <span className={styles.dialogFeedback__thumbdown} onClick={() => evaluateResponse('down', conversationId, idx)}>
                <ThumbDownOffAltIcon></ThumbDownOffAltIcon>
            </span>
            <span className={styles.dialogFeedback__thumbup} onClick={() => evaluateResponse('up', conversationId, idx)}>
                <ThumbUpOffAltIcon></ThumbUpOffAltIcon>
            </span>
        </div>
    )
}

export { FeedbackComponent }

