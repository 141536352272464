
import { useContext } from 'react';

import { ChatUserContext, iChatUserContext } from '../../core/chatContext';
import { WelcomeComponent } from '../common/welcome/main';
import { FooterComponent } from '../chat/footer/footer'; 
import { PromptWithOutAttachedComponent } from '../common/prompt';
import { DialogComponent } from '../common/dialog';

import styles from './ai-operator.module.scss';

const AiOperatorComponent: React.FC<{ idUser: string, userPhoto: any }> = ({ idUser, userPhoto }) => {

    const currChatContext: iChatUserContext | null = useContext(ChatUserContext);
   
    const showMainComponent = () => {
        if ( !currChatContext?.currChat.hasOwnProperty('messages') || currChatContext.currChat.messages.length === 0) {
            return (
                <WelcomeComponent/>
            )
        } else {
            return (
                <DialogComponent 
                    userId={idUser}
                    userPhoto={userPhoto} 
                    userAsks={currChatContext.userAsks} 
                    iaAnswers={currChatContext.iaAnswers}
                    enabledFeedBack={false}
                ></DialogComponent>
            )
        }
    }
    return(
      <div className={styles.chatComponent}>
        {showMainComponent()}
        <div className={styles.promptContainer}>
          <PromptWithOutAttachedComponent url={'/v1/domain/network'}></PromptWithOutAttachedComponent>
        </div>
        <FooterComponent />
      </div>
    )    
}

export {AiOperatorComponent}

