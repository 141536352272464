// App.tsx
import { useEffect, useState } from 'react';
import { MsalProvider, AuthenticatedTemplate, useMsal, UnauthenticatedTemplate } from '@azure/msal-react';
import { loginRequest } from './configuration/authConfig';
import { getUserIndex } from './core/userInfo';
import { BrowserRouter as Router, Navigate, Route, Routes, useLocation } from 'react-router-dom';

import { Toaster } from 'react-hot-toast';
import { ChatPage } from './pages/chat/chat';
import { InsightsPage } from './pages/insisghts/insights';
import { AiOperatorNetworkPage } from './pages/ai-operator-network/main';

import styles from './App.module.scss';
import logoSeat from './assets/img/AF_LOGO_SEAT_SA_negativo_RGB.svg';
import logoHai from './assets/img/Vortex_AI_Title_Blank.svg';
import { AccountInfo, InteractionRequiredAuthError, InteractionStatus } from '@azure/msal-browser';
/**
 * Most applications will need to conditionally render certain components based on whether a user is signed in or not. 
 * msal-react provides 2 easy ways to do this. AuthenticatedTemplate and UnauthenticatedTemplate components will 
 * only render their children if a user is authenticated or unauthenticated, respectively. For more, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
 */
// const MainContent = () => {
const MainContent: React.FC<{vortexInstance: any}> = ({vortexInstance}) => {
    /**
     * useMsal is hook that returns the PublicClientApplication instance,
     * that tells you what msal is currently doing. For more, visit:
     * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/hooks.md
     */
    const {instance, inProgress, accounts} = useMsal();

    const [activeAccount, setActiveAccount] = useState < AccountInfo | null > (null)
    const [userId, setUserId] = useState < string > ('null');
    const [userChatIdx, setUserChatIdx] = useState < UserProfile | null > (null);
    const [accessToken, setAccessToken] = useState<any>(null)

    const location = useLocation();

    useEffect(() => {
        if (inProgress === InteractionStatus.None && accounts.length > 0) {
            const accessTokenRequest = {
                scopes: ["user.read", "group.read.all"],
                account: accounts[0],
            };

            instance.acquireTokenSilent(accessTokenRequest)
                .then(async (accessTokenResponse) => {
                    const userChatIdx = await getUserIndex({
                        accessTokenResponse,
                        "localAccountId": accessTokenRequest.account.localAccountId
                    });
                    setUserId(accessTokenRequest.account.localAccountId);
                    setUserChatIdx(userChatIdx);
                    setActiveAccount(instance.getActiveAccount());
                    setAccessToken(accessTokenResponse.account.idToken);
                    sessionStorage.setItem('__authVortexOrquestator', accessTokenResponse.account.idToken ? accessTokenResponse.account.idToken : '')
                    
                })
                .catch((error) => {
                    if (error instanceof InteractionRequiredAuthError) {
                        instance.acquireTokenPopup(accessTokenRequest)
                            .then(async (accessTokenResponse) => {
                                const userChatIdx = await getUserIndex({
                                    accessTokenResponse,
                                    "localAccountId": accessTokenRequest.account.localAccountId
                                });
                                setUserId(accessTokenRequest.account.localAccountId);
                                setUserChatIdx(userChatIdx);
                                setActiveAccount(instance.getActiveAccount());
                                setAccessToken(accessTokenResponse.account.idToken);
                                sessionStorage.setItem('__authVortexOrquestion', accessTokenResponse.account.idToken ? accessTokenResponse.account.idToken : '')
                            })
                            .catch(error => console.log(error));
                    } else {
                        console.log(error);
                    }
                });
        }
    }, [instance, accounts, inProgress]);

    const handleRedirect = () => {
        // Save vortexClient
        // Default location is chat
        vortexInstance.vortexClient = location.pathname === '/' ? '/chat' : location.pathname;
        vortexInstance.saveToSessionStorage();

        instance
            .loginRedirect({
                ...loginRequest,
                prompt: 'create',
            })
            .catch((error) => console.log(error));
    };   

    return (
        <>
            <AuthenticatedTemplate>
                {activeAccount ? (
                    <Routes>
                        <Route path="/" element={<Navigate to="/chat" replace/>}/>
                        <Route path="/chat" 
                            element={<ChatPage userId={userId} userChatIdx={userChatIdx} accessToken={accessToken}></ChatPage>}
                        ></Route>
                        <Route path="/insights" 
                            element={<InsightsPage userId={userId} userChatIdx={userChatIdx} accessToken={accessToken}></InsightsPage>}>
                        </Route>
                        <Route path="/ai-operator-networks" 
                            element={<AiOperatorNetworkPage userId={userId} userChatIdx={userChatIdx} accessToken={accessToken}></AiOperatorNetworkPage>}>
                        </Route>
                    </Routes>
                ) : null}
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                <div className={styles.login}>
                    <div className={styles.loginHeader}>
                        <div className={styles.loginHeaderButton} onClick={handleRedirect} role="button">Login</div>
                    </div>
                    <div className={styles.loginLogos}>
                        <img src={logoHai} className={styles.loginLogosVortex} alt="logo" />
                        <img src={logoSeat} className={styles.loginLogosSeat} alt="logo" />
                    </div>
                </div>
            </UnauthenticatedTemplate>
                <Toaster position="top-center" reverseOrder={false} />

        </>
    );
};


/**
 * msal-react is built on the React context API and all parts of your app that require authentication must be 
 * wrapped in the MsalProvider component. You will first need to initialize an instance of PublicClientApplication 
 * then pass this to MsalProvider as a prop. All components underneath MsalProvider will have access to the 
 * PublicClientApplication instance via context as well as all hooks and components provided by msal-react. For more, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
 */
const App = (props: any) => {
    const { instance, vortexInstance } = props;
    return (
        <MsalProvider instance={instance}>
            <Router>
                <MainContent vortexInstance={vortexInstance}/> 
            </Router>
        </MsalProvider>
    );
}


export { App }