/* index.tsx */

import { fetchWithToken } from "../interceptor";

export async function loginUser(app_id: string, user_id: string) {
    return new Promise( async(resolve: any) => {
        try {
            await fetchWithToken(`/v1/users/login/${app_id}/${user_id}`);
        } catch (error)   {
            console.error('Error creating new user', error);
        }
        resolve(true)
    })
}

/**
 * Logs out the user by calling the logout API and clearing the session storage.
 *
 * @param app_id - The application ID.
 * @param user_id - The user ID.
 * @returns void
 */
export function logoutUser(app_id: string, user_id: string): void{
    ( async(app_id: string, user_id: string) => {
        try {
            await fetchWithToken(`/v1/users/logout/${app_id}/${user_id}`);
        } catch (error) {
            console.error('Error updating conversation', error);
        }
        sessionStorage.clear();
    })(app_id, user_id);
}

