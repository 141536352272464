import { useEffect, useState } from 'react';

import { ChatUserContext, iChatUserContext, InitalChatRequest } from '../../core/chatContext';

import { HeaderComponent } from '../../components/common/header/main';
import { MenuComponent } from '../../components/common/menu/main';

import { AiOperatorComponent } from '../../components/ai-operator/ai-operator';

import styles from './main.module.scss';
import logoHAI from './../../assets/img/Vortex_AI_Title_Blank.svg';
import { NewChatComponent } from '../../components/common/newChat/newChat';
import { useMsal } from '@azure/msal-react';
import { NOT_AUTH_USER, VORTEX_ID } from '../../configuration/vortexConfig';
import { Logout } from '@mui/icons-material';
import { ChatController } from '../../components/chat/chat.controller';
import { loginUser, logoutUser } from '../../core/users';

const AiOperatorNetworkPage: React.FC<{userId: string, userChatIdx: UserProfile | null, accessToken: string | null}> = ({userId, userChatIdx, accessToken = null}) => {    
    
    const [currChat, setCurrChat] = useState(InitalChatRequest);
    const [currHistory, setCurrHistory] = useState([]);
    const [userAsks, setUserAsks] = useState([] as Array<string>);
    const [iaAnswers, setIaAnswers] = useState([] as DialogComponents[]);

    useEffect(() => {

        const _registerUser = async () => {
            await loginUser(VORTEX_ID, userId)
        }

        const _handleBeforeUnload = async () => {
            if (currChat.data_sources[0].parameters.ephemeralId) {
                const Chat_Controller = new ChatController()
                await Chat_Controller.RemoveFile(currChat.data_sources[0].parameters.ephemeralId);
            };
            logoutUser(VORTEX_ID, userId);
        };

        // Register login user
        _registerUser();

        // Add user index to request parameters to call openai api
        setCurrChat((currChat: RequestOpenAI) => {
            currChat.data_sources[0].parameters.index_name = 'nocgeneral';
            currChat.data_sources[0].parameters.semantic_configuration = 'nocgeneral-semantic-configuration';
            return currChat;
        });

        // Add event before unload
        window.addEventListener('beforeunload', _handleBeforeUnload);
        return () => {
            window.removeEventListener('beforeunload', _handleBeforeUnload);
        };
    }, [userChatIdx, userId, currChat]);

    const NotAutorizedComponent = () => {
        const { instance } = useMsal();

        const handleLogoutRedirect = () => {
            instance.logoutRedirect().catch((error) => console.log(error));
        };

        return (
            <div className={styles.chatError}>
                <div className={styles.chatErrorMessage}>{NOT_AUTH_USER}</div>
                <div className={styles.chatErrorLogOut} onClick={handleLogoutRedirect} role="button">
                    <Logout></Logout>
                    <span>Log Out</span>
                </div>
            </div>
        )
    }
        
    return (
        <ChatUserContext.Provider value={
            {
                currChat, setCurrChat,
                currHistory, setCurrHistory,
                userAsks, setUserAsks,
                iaAnswers, setIaAnswers,
                accessToken: accessToken
            } as iChatUserContext
        }>
        <span className={styles.root}>
            <header style={{ height: '80px' }}>
                <HeaderComponent userChatIdx={userChatIdx} headerProps={{srcImage:logoHAI, enabledContext:false}}></HeaderComponent> 
            </header>
            {(userChatIdx?.authorizedUser && userChatIdx?.index)
                ?
                <div className={styles.menu}>
                    <MenuComponent idUser={userId} status={false}>
                        <div style={{ padding: "5px"}}>
                            <NewChatComponent></NewChatComponent>
                        </div>
                        <div style={{ overflowY: "auto" }}>
                        </div>
                        <div style={{ padding: "2.5px 5px"}}>
                        </div>
                    </MenuComponent>

                    <div className={styles.chat}>
                        <AiOperatorComponent idUser={userId} userPhoto={userChatIdx?.userPhoto}></AiOperatorComponent>
                    </div>
                </div>
                :
                    <NotAutorizedComponent />
            }
        </span>            
        </ChatUserContext.Provider>
    )
}

export { AiOperatorNetworkPage }

