// userInfo/index.tsx

import { USER_GROUPS, AUTH_GROUP } from "../../configuration/vortexConfig";

export const getUserIndex = (parameters: { accessTokenResponse: any, localAccountId: string}): Promise<UserProfile | null> => {
    const { accessTokenResponse } = {...parameters};

    function _checkIsAuthorizedUser(message: MSGraphsGroups): boolean {
        const groups = message.value.filter((group: MSGraphsGroupsValue) => group.displayName === AUTH_GROUP);
        return groups.length ? true : false;
    }

    async function fetchDataFromStream(url: string, options: any): Promise<string> {
        try {
            const response = await fetch(url, options);
            if (!response.ok) {
                throw new Error(`HTTP ${response.status}: ${await response.text()}`);
            }

            // Lee el flujo de datos como texto
            const textData = await response.text();
            return textData;
        } catch (error) {
            console.error("Error al obtener los datos del flujo:", error);
            return ""; // Devuelve una cadena vacía en caso de error
        }
    }

    function getIndex(message: MSGraphsGroups): { 
            authorizedUser: boolean, 
            userIndex: string | null, 
            allowIndexes: AllowIndexes[] , 
            semanticConfiguration: string | null
        } {
            
        let authorizedUser: boolean = false;
        let userIndex: string | null = null;
        // let indexDescription: string | null = null
        let semanticConfiguration: string | null = null
        let allowIndexes:AllowIndexes[] = [];
        // Check if user belong to auth users group
        authorizedUser = _checkIsAuthorizedUser(message);

        if ( authorizedUser ) {
            message.value.forEach((group: MSGraphsGroupsValue) => {
                // Check if group displayName is in user groups list
                if ( group.displayName && group.displayName in USER_GROUPS) {
                    // If group have description add to allowIndex
                    if ( group.description ) {
                        allowIndexes.push({
                            "displayName": USER_GROUPS[group.displayName]['index'],
                            "description": group.description,
                            "semanticConfiguration": USER_GROUPS[group.displayName]['semantic_configuration']
                        });
                        // If not asign default value yet, asign first coincidence to user
                        if (!userIndex ) {
                            userIndex = USER_GROUPS[group.displayName]['index'];
                            semanticConfiguration = USER_GROUPS[group.displayName]['semantic_configuration'];
                        } 
                    }
                }
            });
        }
        return {
            authorizedUser,
            userIndex,
            allowIndexes,
            semanticConfiguration
        }
    }

    async function getPhoto(url: string, options: any){
        try {
            const response = await fetch(url, options);
            if (!response.ok) {
                throw new Error(`HTTP ${response.status}: ${await response.text()}`);
            }

            // Lee el flujo de datos como imagen
            const image = await response.blob();
            if (image !== null) {
                window.URL = window.URL || window.webkitURL;
                return window.URL.createObjectURL(image);
            }
        } catch (error) {
            console.error("Error al obtener los datos del flujo:", error);
            return ""; // Devuelve una cadena vacía en caso de error
        }
    }

    return new Promise( (resolve) => {
        const headers = new Headers();
        const bearer = "Bearer " + accessTokenResponse.accessToken;

        console.log(accessTokenResponse.accessToken);
        
        headers.append("Authorization", bearer);
        headers.append("ConsistencyLevel", "eventual" )

        var options = {
                method: "GET",
                headers: headers
        };
        
        // Filter groups. DisplayName contains vortex and limit result is 999
        fetchDataFromStream(`https://graph.microsoft.com/v1.0/me/memberOf?$search="displayName:vortex"&$top=999`, options)
        // fetchDataFromStream(`https://graph.microsoft.com/v1.0/me/memberOf`, options)
            .then(async (decodedMessage) => {
                const userPhoto = await getPhoto(`https://graph.microsoft.com/v1.0/me/photos/48x48/$value`, options)
                const { authorizedUser, userIndex, allowIndexes, semanticConfiguration } = {  ...getIndex(JSON.parse(decodedMessage)) }
                resolve({
                    "authorizedUser": authorizedUser,
                    "name": accessTokenResponse.account.name,
                    "username": accessTokenResponse.account.username,
                    "index": userIndex,
                    "semanticConfiguration": semanticConfiguration, 
                    "allowIndexes": allowIndexes,
                    "userPhoto": userPhoto
                });
            })
            .catch((error) => {
                console.error("Error general:", error);
                resolve(null)
            });
    })
}
