// menu.scss
import { useState, ReactNode } from 'react';

import Menu from '@mui/icons-material/Menu';
import Close from '@mui/icons-material/Close';


import styles from './main.module.scss';
import { LogOutComponent } from './logout/logout';

interface HeaderElementProps {
    isMenuOpen: boolean;
    toggleMenu: () => void;
}

interface MenuComponentProps {
    idUser: string;
    status: boolean;
    children?: ReactNode;
}

const HeaderElement: React.FC<HeaderElementProps> = ({ isMenuOpen, toggleMenu}) => {
    return(
        <div className={styles.menuIcon} onClick={toggleMenu} role="button">
            <div className={styles.menuIconMenu} style={{ display: isMenuOpen ? 'none' : 'block' }}>
                <Menu style={{fill: "#2c2a29"}}></Menu>
            </div>
            <div className={styles.menuIconClose} style={{ display: isMenuOpen ? 'block' : 'none' }}>
                <Close style={{fill: "#2c2a29"}}></Close>
            </div>
        </div>
    )
}

const MenuComponent: React.FC<MenuComponentProps> = ({ idUser, status, children }) => {
    const [isMenuOpen, setIsMenuOpen] = useState(status);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen)
    };

    const closeMenu = () => {
        setIsMenuOpen(false);
    };

    (MenuComponent as any).closeMenu = closeMenu;
    
    const displayOpenMenu = () => {
        return (
            <div className={styles.menuOptions}>
                <HeaderElement isMenuOpen={isMenuOpen} toggleMenu={toggleMenu} />
                {children}
                <div style={{ padding: "2.5px 5px", marginTop: "auto"}}>
                    <LogOutComponent/>   
                </div>
            </div>
        )
    };

    const displayCloseMenu = () => {
        return (
            <div className={styles.menuNotActive}>
                <HeaderElement isMenuOpen={isMenuOpen} toggleMenu={toggleMenu} />
            </div>
        )

    };

    return isMenuOpen ? displayOpenMenu() : displayCloseMenu();
}

export { MenuComponent }