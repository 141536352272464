import { fetchWithToken  } from "../interceptor";
import { Conversation  } from "./models";
export class CosmosConn {

    private createNewUser(accessToken: string, conversation: Conversation): Promise<any>{
        return new Promise( async(resolve) => {

            const newUser: {userId: string, conversation: Conversation[]} = {
                "userId": conversation.user,
                "conversation": [conversation]
            }

            const requestOptions = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${accessToken}`
                },
                body: JSON.stringify(newUser),
            };

            try {
                await fetchWithToken('/v1/history/user', requestOptions)
            }
            catch (error) {
                console.error('Error creating new user', error);
            }
            resolve(true);
        });
    }

    private updateConversation(accessToken: string, conversation: Conversation): Promise<any>{
        return new Promise( async(resolve) => {
            const requestOptions = {
                method: "PATCH",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(conversation)
            };
            try {
                await fetchWithToken(`/v1/history/user/${conversation.user}/conversation/${conversation.id}`, requestOptions);
            } catch (error) {
                console.error('Error updating conversation', error);
            }
            resolve(true);
        });
    }
    
    fetchChatHistoryInit(idUser: string): Promise<any> {
        return new Promise( async(resolve: any) => {
            fetchWithToken(`/v1/history/read/${idUser}`)
                .then(response => response.json())
                .then(data => {
                    if ( data ) resolve(JSON.parse(data));
                    resolve([]);
                })
                .catch(error => {
                    console.error(error);
                    resolve([])
                });
        })
    }

    addConversation(accessToken: string, conversation: Conversation | null): Promise<boolean> {
        return new Promise( async(resolve: any) => {
            try {
                // Check if current user exists.
                const response = await fetchWithToken(`/v1/history/check/${conversation?.user || 0}`);
                if ( response.status === 204 ) {
                    // Create new user and add conversation
                    if ( conversation ) await this.createNewUser(accessToken, conversation);
                } 

                if ( response.status === 200 ) {
                    if ( conversation ) await this.updateConversation(accessToken, conversation);
                }
            }
            catch (error) {
                console.error('Error adding conversation', error);
            }
            resolve(true)
        })
    }

    deleteConversation(conversation: Conversation, messageId: number): Promise<boolean>{
        return new Promise( async(resolve: any) => {
            const requestOptions = {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json"
                }
            };

            try {
                const response = await fetchWithToken(`/v1/history/user/${conversation.user}/conversation/${conversation.id}/${messageId}`, requestOptions);
                resolve(response.status === 200);
            } catch (error) {
                console.error('Error remove message from conversation', error);
                resolve(false)
            }
        })
    }

    evaluateConversation(userId: string, conversationId: string, messageId: string, evaluation: string): Promise<boolean>{
        return new Promise( async(resolve: any) => {
            const requestOptions = {
                method: "PATCH",
                headers: {
                    "Content-Type": "application/json"
                }
            };

            try {
                const response = await fetchWithToken(`/v1/history/user/${userId}/conversation/${conversationId}/${messageId}/evaluate/${evaluation}`, requestOptions);
                resolve(response.status === 200);
            } catch (error) {
                console.error('Error remove message from conversation', error);
                resolve(false)
            }
        })

    }
}


