/* index.tsx */

import { parserMessage } from "../parser";

import { CHAT_ROLE } from "../../configuration/vortexConfig";
import { fetchWithToken } from "../interceptor";
import { iChatUserContext } from "../chatContext";
// import { getAPIResponse } from "../http";

export async function callHttpApi(aiApiRequest: RequestOpenAI, accessToken: string) {
    let _url = '' 
    if (aiApiRequest.data_sources[0].parameters.ephemeralId) {
        _url = `/v1/ephemeral/completion/${aiApiRequest.data_sources[0].parameters.ephemeralId}`
    } else {
        _url = `/v1/chat/completion`
    }
   
    const _requestOptions = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${accessToken}`
        },
        body: JSON.stringify({
            "role": CHAT_ROLE,
            "question": JSON.stringify(aiApiRequest.messages),
            "index": aiApiRequest.data_sources[0].parameters.index_name,
            "semantic_configuration": aiApiRequest.data_sources[0].parameters.semantic_configuration,
            "temperature": aiApiRequest.temperature,
            "max_n_tokens": aiApiRequest.max_tokens,
        }),
    };
    return await fetchWithToken(_url, _requestOptions)
    
}

export async function getAPIResponse(
    bodyApiRequest: RequestOpenAI,
    accessToken: string,
    onChunkReceived: (chunk: any) => void,
    abortController: AbortController // Add AbortController as a parameter
): Promise<any> {
    return new Promise((resolve, reject) => {
        const apiResponse: any[] = [];
        try {
            callHttpApi(bodyApiRequest, accessToken)
                .then(async (response) => {
                    if (response.status === 200) {
                        const stream = response?.body;
                        const reader = stream!.getReader();
                        const readChunk = () => {
                            reader.read()
                                .then(({ value, done }) => {
                                    if (abortController.signal.aborted) {
                                        reject("Request aborted by user");
                                        return;
                                    }
                                    if (done) {
                                        resolve('End request');
                                        return;
                                    }
                                    const chunkString = new TextDecoder().decode(value);
                                    parserMessage(chunkString).then((chunk) => {
                                        apiResponse.push(...chunk);
                                        onChunkReceived(chunk);
                                    });
                                    readChunk();
                                })
                                .catch(error => {
                                    console.error(error);
                                    resolve([{
                                        "index": 0,
                                        "delta": {
                                            "content": "Oops, parece que hay problema de conexión con el servidor central."
                                        },
                                        "end_turn": false,
                                        "finish_reason": null
                                    }]);
                                });
                        };
                        readChunk();
                    } else {
                        resolve([{
                            "index": 0,
                            "delta": {
                                "content": "Oops, parece que hay problema de conexión con el servidor central."
                            },
                            "end_turn": false,
                            "finish_reason": null
                        }]);
                    }
                })
                .catch((error) => {
                    console.error("Error al realizar la llamada fetch:", error);
                    reject(error);
                });
        } catch (error) {
            console.error('Error', error);
            reject(error);
        }
    });
}

export async function callAiOperator(
    currChatContext: iChatUserContext | null, 
    url: string,  
    onChunkReceived: (chunk: any) => void,
    abortController: AbortController
): Promise<any> {
    return new Promise(async(resolve, reject) => {
        const _requestOptions = {
            method: "POST",
            body: JSON.stringify({
                "role": CHAT_ROLE,
                "question": JSON.stringify(currChatContext?.currChat.messages),
                "index": currChatContext?.currChat.data_sources[0].parameters.index_name,
                "semantic_configuration": currChatContext?.currChat.data_sources[0].parameters.semantic_configuration,
                "temperature": currChatContext?.currChat.temperature,
                "max_n_tokens": currChatContext?.currChat.max_tokens,
            }),
            signal: abortController.signal // Add the signal to the request options
        };

        let response = null;
        try {
            response = await fetchWithToken(url, _requestOptions);
        } catch (error) {
            console.error("Error al realizar la llamada fetch:", error);
            reject("Request aborted");
        }
        const readerStream = response?.body?.getReader();
        if (!readerStream) {
            resolve([{
                "index": 0,
                "delta": {
                    "content": "Oops, parece que hay problema de conexión con el servidor central."
                },
                "end_turn": false,
                "finish_reason": null
            }]);
            return;
        }
        let apiResponse = [];

        const readChunk = () => {
            readerStream.read()
                .then(({ value, done }) => {
                    if (abortController.signal.aborted) {
                        reject("Request aborted");
                        return;
                    }
                    if (done) {
                        resolve('End request');
                        return;
                    }
                    const chunkString = new TextDecoder().decode(value);
                    parserMessage(chunkString).then((chunk) => {
                        apiResponse.push(...chunk);
                        onChunkReceived(chunk);
                    });
                    readChunk();
                })
                .catch(error => {
                    console.error(error);
                    resolve([{
                        "index": 0,
                        "delta": {
                            "content": "Oops, parece que hay problema de conexión con el servidor central."
                        },
                        "end_turn": false,
                        "finish_reason": null
                    }]);
                });
        };
        readChunk();
    })



}

